
.back-button {
  left: 0.75rem;
  top: 0.75rem;
  position: absolute;
}

.diff {
  font-family: "Inter", "Inter Variable", sans-serif;
  padding: 2em;
}

.rows {
  --gap: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.row {
  --size: 2rem;
  --spacing: 0.25em;
  align-items: center;
  display: grid;
  gap: var(--gap);
  line-height: 1;
  grid-template-columns: 1fr;

  &.is-parent {
    grid-template-columns: 1fr 10em;
    padding-top: 1em;

    &:first-of-type {
      padding-top: 0;
    }
  }

  &.is-display {
    grid-template-columns: 1fr 20em;
  }

  &.is-disabled {
    opacity: 0.3;

    .current-value,
    .draft-value {
      pointer-events: none;
    }
  }
}

.row-steps {
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.row-step-label {
  color: var(--stk-text-strong);
  font-weight: 600;

  &:last-child {
    color: var(--stk-primary);
  }
}

.row-step-slash {
  color: var(--stk-text-weak);
}

.row-body {
  background-color: var(--stk-background-alt);
  border-radius: 0.5em;
  align-items: center;
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr 10em 20em 1.25em 20em 2.5em 15em;
  padding: 0.5em;
}

.row-path {
  padding-left: 0.5em;
}

.row-badge {
  text-transform: capitalize;
}

.row-direction {
  color: var(--stk-primary);
}

.current-value,
.draft-value {
  display: flex;
}

.current-value {
  justify-content: end;
}

.row-input {
  width: 100%;

  &.is-fake,
  &.is-empty {
    background: none;
    border-color: transparent;
    box-shadow: none;
    pointer-events: none;
  }

  &.is-fake {
    text-align: right;
    padding-left: 0;
    padding-right: 0;
  }

  &.is-empty {
    font-style: italic;
    opacity: 0.3;
  }
}

.arm {
  border-radius: 0.5em;
  height: var(--size);
  width: var(--size);
  position: relative;

  &::before,
  &::after {
    background-color: var(--stk-text-80);
    content: "";
    display: block;
    position: absolute;
  }

  &::before {
    height: 50%;
    left: 50%;
    top: 0;
    width: 2px;
  }

  &::after {
    width: 50%;
    top: 50%;
    left: 50%;
    height: 2px;
  }
}

.loading {
  padding: 2em;
  text-align: center;
  color: var(--stk-text-strong);
  font-weight: 500;
}

.row-source {
  margin-left: 0.5em;
  position: relative;
  max-width: 14em;
}

.source-button-wrapper {
  position: relative;
  width: 100%;
}

.source-button {
  cursor: pointer;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--stk-background);
  border: 1px solid #e2e2e2;
  color: var(--stk-text);
  width: 100%;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s, box-shadow 0.2s;
}

.source-button:hover {
  border-color: #c0c0c0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.source-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.source-main {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  font-weight: 500;
}

.source-timestamp {
  font-size: 0.7rem;
  color: var(--stk-text-weak);
  font-weight: normal;
}

.confidence-indicator {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: #aaa;
}

.indicator-wrapper {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}

.bar-chart-icon {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  height: 0.75rem;
  margin-top: 1px;
}

.bar {
  width: 2px;
  border-radius: 1px;
}

.bar-1 {
  height: 5px;
  background-color: #ddd;
}

.bar-2 {
  height: 9px;
  background-color: #ddd;
}

.bar-3 {
  height: 13px;
  background-color: #ddd;
}

.level-high .bar-1,
.level-high .bar-2,
.level-high .bar-3 {
  background-color: #4caf50;
}

.level-medium .bar-1,
.level-medium .bar-2 {
  background-color: #4caf50;
}

.level-medium .bar-3 {
  background-color: #ddd;
}

.level-low .bar-1 {
  background-color: #4caf50;
}

.level-low .bar-2,
.level-low .bar-3 {
  background-color: #ddd;
}

/* Remove the old indicator dot styles */
.indicator-dot {
  display: none;
}

.source-tooltip {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: var(--stk-background-alt);
  border: 1px solid var(--stk-border);
  border-radius: 0.25rem;
  padding: 0.5rem;
  max-width: 250px;
  z-index: 10;
  font-size: 0.8rem;
  color: var(--stk-text);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.source-button-wrapper:hover .source-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Remove the old confidence styling that's no longer needed */
.confidence-high, .confidence-medium, .confidence-low {
  border-left: none;
}

/* Save button styles */
.save-button-container {
  background-color: #ebf7ee;
  border: 1px solid #34a853;
  border-radius: 6px;
  position: sticky;
  bottom: 1rem;
  margin: 2rem 1rem 1rem 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.save-button-container:hover {
  background-color: #ddf2e3;
}

.save-button-content {
  display: flex;
  align-items: center;
}

.banner-icon {
  margin-right: 10px;
  color: #34a853;
  font-size: 1.1em;
}

.banner-button {
  background-color: #4caf50 !important;
  border-color: #45a049 !important;
  color: white !important;
}

.banner-button:hover {
  background-color: #45a049 !important;
  border-color: #3d8b3d !important;
}

.save-button-wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-shrink: 0;
}
