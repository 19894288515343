
.body {
  --offset: 1rem;
  align-items: start;
  display: flex;
  gap: 2rem;
  margin-top: calc(-1 * var(--offset));
}

.side {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  width: 14rem;
  padding-top: var(--offset);
}

.main {
  flex-grow: 1;
  padding-top: var(--offset);
  min-height: 40rem;

  &.edit-mode {
    background-color: #FCFCFD;
  }
}

.dossier-update-banner {
  background-color: #ebf7ee;
  border: 1px solid #34a853;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.banner-content {
  display: flex;
  align-items: center;
}

.banner-icon {
  margin-right: 10px;
  color: #34a853;
  font-size: 1.1em;
}

.banner-button {
  flex-shrink: 0;
  background-color: #4caf50 !important;
  border-color: #45a049 !important;
  color: white !important;
}

.banner-button:hover {
  background-color: #45a049 !important;
  border-color: #3d8b3d !important;
}

.dossier-update-banner:hover {
  background-color: #ddf2e3;
}

.master-upload {
  width: 60rem;
}

.print-radio {
  cursor: pointer;
}

.print-box {
  padding: 1em;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media print {
  .component {
    font-size: 0.625rem;
    padding: 0 !important;
  }

  .header,
  .onboarding,
  .side
  {
    display: none !important;
  }
}
